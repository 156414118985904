import { Navigate, useSearchParams } from '@remix-run/react';
import { useTitle } from 'react-use';
import { $path } from 'remix-routes';

import { OnDemandEventCreate } from '../../components/Event/Create';
import { PublicHomeLayout } from '../../components/PublicHome/Layout';
import { useFeatureQueryParam } from '../../hooks/useFeatureQueryParam';
import { makeTitle } from '../../utils/common';
import { DateUtils } from '../../utils/date';

// eslint-disable-next-line import/no-default-export
export default function MyEventCreate(): JSX.Element | null {
  useTitle(makeTitle('Schedule an Event'));
  const [searchParams] = useSearchParams();
  const backTo = searchParams.get('back-to') ?? undefined;
  const gamePackId = searchParams.get('game-pack-id') ?? undefined;
  const eventTime =
    DateUtils.ParseEventTime(
      searchParams.get('start-date'),
      searchParams.get('timezone')
    ) ?? undefined;

  const durationMinRaw = searchParams.get('duration-min') ?? undefined;
  let durationMin = undefined;
  if (durationMinRaw) {
    durationMin = Math.max(0, parseInt(durationMinRaw, 10));
  }
  const fromOtp = searchParams.get('from-otp') === 'true';
  const newSchedulerEnabled = useFeatureQueryParam('new-event-scheduler');

  if (newSchedulerEnabled) {
    return (
      <Navigate
        to={$path('/events/schedule/:type?', {}, window.location.search)}
        replace
      />
    );
  }

  return (
    <PublicHomeLayout>
      <OnDemandEventCreate
        backTo={backTo}
        gamePackId={gamePackId}
        eventTime={eventTime}
        durationMin={durationMin}
        fromOtp={fromOtp}
      />
    </PublicHomeLayout>
  );
}
